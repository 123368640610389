<template>
  <div ref="templateDropDownButton">
    <t-button btn-style="secondary" size="sm" @click="toggleTemplateDropdown">
      <div class="flex flex-row items-center">
        <span>{{ waTemplate.title }}</span>
        <chevron-down-linear class="ml-2" size="1.5rem" />
      </div>
    </t-button>

    <div
      v-show="openTemplateDropdown"
      ref="templateDropDown"
      class="absolute z-50 mt-2 w-96 rounded-xl border border-grey-300 bg-white shadow-500 h-[420px] flex flex-col"
    >
      <div class="flex flex-col">
        <div class="flex justify-start p-2 gap-2 border-b border-gray-300">
          <div class="p-1 border rounded-md border-gray-500">
            <CommentDotsLinear size="1.1rem" />
          </div>
          <span class="text-lg font-bold">{{ $t('tickets.wa_select_template_dropdown_title') }}</span>
        </div>
        <div class="p-2 border-b border-gray-100">
          <t-input-search
            v-model="wa_template_search"
            size="sm"
            :placeholder="$t('tickets.wa_select_template_dropdown_placeholder')"
            @input="onSearchKeywordChange"
            @clear="onSearchKeywordClear"
            @keydown="onKeyDownWaTemplates"
          />
        </div>
      </div>
      <div
        v-if="filteredWhatsappTemplates.length === 0"
        class="p-2 h-80 flex flex-col flex-grow items-center justify-center gap-2"
      >
        <div class="h-10 w-10 p-2 border rounded-md border-gray-500">
          <SearchLinear size="1.5rem" />
        </div>
        <p>{{ $t('tickets.wa_select_template_dropdown_no_match') }} '{{ wa_template_search }}'</p>
      </div>
      <div v-else ref="templateDropDownList" class="grow p-2 overflow-x-hidden overflow-y-auto">
        <t-list-item
          v-for="(template, index) in filteredWhatsappTemplates"
          :key="template.text"
          v-tooltip="{
            content: template.text,
            delay: { show: 500, hide: 50 },
          }"
          class="cursor-pointer overflow-x-hidden mb-1"
          :label="trimmedTemplateTitle(template.text)"
          :value="template.value"
          :active="waTemplateSelectedIndex === index"
          @click="setWhatsappTemplate(template)"
          @mouseenter="updateHoveredTemplateIndex(index)"
        >
          <template #postfix>
            <star-fill
              v-if="displayGreyFavouriteIcon(template, index)"
              class="text-grey"
              @click.stop="markTemplateAsFavourite(template)"
            />
            <star-fill
              v-if="displayYellowFavouriteIcon(template)"
              class="text-yellow"
              @click.stop="unMarkTemplateAsFavourite(template)"
            />
          </template>
        </t-list-item>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CommentDotsLinear, ChevronDownLinear, SearchLinear, StarFill } from '@trengo/trengo-icons';
import { hideAllPoppers } from 'floating-vue';
import { ref, computed } from 'vue';

import { starWaTemplate, unStarWaTemplate } from '../api';

import type { FilteredWhatsappTemplate } from '../types/composer';
import type { Template } from '@/types/waTemplates';

const props = defineProps<{
  waTemplate: Template;
  waTemplateMessages: Template[];
}>();

const emit = defineEmits(['set-whatsapp-template', 're-fetch-whatsapp-templates']);

const openTemplateDropdown = ref(false);
const wa_template_search = ref('');
const hoveredTemplateIndex = ref(-1);
const selectedWaTemplate = ref<Template | null>(null);
const waTemplateSelectedIndex = ref(-1);
const templateDropDownList = ref<HTMLElement | null>(null);
const templateDropDownButton = ref<HTMLElement | null>(null);
const templateDropDown = ref<HTMLElement | null>(null);

const setWhatsappTemplate = (template: FilteredWhatsappTemplate) => {
  selectedWaTemplate.value = template.value;
  emit('set-whatsapp-template', template);
  openTemplateDropdown.value = false;
};

const onScroll = () => {
  hideAllPoppers();
};

const onKeyDownWaTemplates = ($event: KeyboardEvent) => {
  if ($event.key === 'Enter') {
    $event.preventDefault();
    if (filteredWhatsappTemplates.value.length === 0) {
      return;
    }
    setWhatsappTemplate(filteredWhatsappTemplates.value[waTemplateSelectedIndex.value]);
    wa_template_search.value = '';
    openTemplateDropdown.value = false;
  }
  if ($event.key === 'ArrowDown' && filteredWhatsappTemplates.value.length > 0) {
    $event.preventDefault();
    if (waTemplateSelectedIndex.value < filteredWhatsappTemplates.value.length - 1) {
      waTemplateSelectedIndex.value++;
    }
  }
  if ($event.key === 'ArrowUp' && filteredWhatsappTemplates.value.length > 0) {
    $event.preventDefault();
    if (waTemplateSelectedIndex.value > 0) {
      waTemplateSelectedIndex.value--;
    }
  }
};

const toggleTemplateDropdown = (e: HTMLElementEvent<HTMLInputElement>) => {
  e.preventDefault();
  if (openTemplateDropdown.value) {
    openTemplateDropdown.value = false;
    wa_template_search.value = '';
    if (templateDropDownList.value) {
      templateDropDownList.value.removeEventListener('scroll', onScroll, {});
    }
    return;
  }

  if (templateDropDownList.value) {
    templateDropDownList.value.addEventListener('scroll', onScroll, {});
  }

  waTemplateSelectedIndex.value = filteredWhatsappTemplates.value.findIndex(
    (template: FilteredWhatsappTemplate) => template.slug === props.waTemplate.slug,
  );
  if (templateDropDownButton.value) {
    const elementTop = templateDropDownButton.value.getBoundingClientRect().top + window.scrollY;
    const documentHeight = document.documentElement.scrollHeight;
    const positionFromBottom = documentHeight - elementTop;
    if (positionFromBottom < 335) {
      if (templateDropDown.value) {
        (templateDropDown.value as HTMLElement).style.top = '-355px';
      }
    }
    openTemplateDropdown.value = true;
  }
};

const onSearchKeywordChange = (keyPhrase: string) => {
  wa_template_search.value = keyPhrase;
  waTemplateSelectedIndex.value =
    filteredWhatsappTemplates.value.findIndex((template) => template.slug === props.waTemplate?.slug) ?? -1;
};

const onSearchKeywordClear = () => {
  wa_template_search.value = '';
  waTemplateSelectedIndex.value = filteredWhatsappTemplates.value.findIndex(
    (template: FilteredWhatsappTemplate) => template.slug === props.waTemplate?.slug,
  );
};

const filteredWhatsappTemplates = computed(() => {
  return props.waTemplateMessages
    .filter((template: Template) => {
      return template.title.toLowerCase().includes(wa_template_search.value.toLowerCase());
    })
    .map((item: Template) => ({ value: item, text: `${item.title} - ${item.slug}`, slug: item.slug }));
});

const trimmedTemplateTitle = (text: string) => {
  if (text.length <= 35) {
    return text;
  }

  const splicedText = text.slice(0, 35);
  return `${splicedText}...`;
};

const updateHoveredTemplateIndex = (currIndex: number) => {
  hoveredTemplateIndex.value = currIndex;
};

const displayGreyFavouriteIcon = (template: FilteredWhatsappTemplate, currIndex: number) => {
  const isStarred = template.value.is_starred;
  return hoveredTemplateIndex.value === currIndex && !isStarred;
};

const displayYellowFavouriteIcon = (template: FilteredWhatsappTemplate) => {
  const isStarred = template.value.is_starred;
  return isStarred;
};

const markTemplateAsFavourite = async (template: FilteredWhatsappTemplate) => {
  await starWaTemplate(template.value.id);
  emit('re-fetch-whatsapp-templates');
};

const unMarkTemplateAsFavourite = async (template: FilteredWhatsappTemplate) => {
  await unStarWaTemplate(template.value.id);
  emit('re-fetch-whatsapp-templates');
};
</script>
