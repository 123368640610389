import { onMounted, ref } from 'vue';

import { fetchBalanceForUsageBased, fetchBalanceForSeatBased } from '@/api';
import { useCompanyProfileStore } from '@/store/pinia';
import { formatPrice, toCents } from '@/util/currency';

export const useBalance = () => {
  const formattedBalance = ref('');
  const isLoading = ref(false);
  const rawBalance = ref(0);
  const companyProfileStore = useCompanyProfileStore();

  const getBalanceUsageBased = async () => {
    const { data } = await fetchBalanceForUsageBased();
    return data.balance?.balance ?? 0;
  };

  const getBalanceSeatBased = async () => {
    const { data } = await fetchBalanceForSeatBased();
    return data.balance_unformatted ?? 0;
  };

  const fetchBalance = async () => {
    isLoading.value = true;
    try {
      const balance = await (companyProfileStore.isUsageBasedPricingModel
        ? getBalanceUsageBased()
        : getBalanceSeatBased());

      setBalance(balance);
    } catch (error) {
      console.error('Error fetching balance:', error);
    } finally {
      isLoading.value = false;
    }
  };

  const setBalance = (balance: number) => {
    rawBalance.value = balance;
    formattedBalance.value = formatPrice(toCents(balance));
  };

  onMounted(fetchBalance);

  return {
    formattedBalance,
    rawBalance,
    isLoading,
    fetchBalance,
    setBalance,
  };
};
