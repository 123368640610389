import { Device, type Call } from '@twilio/voice-sdk';
import { defineStore } from 'pinia';
import { type Channel } from 'pusher-js';
import { ref } from 'vue';

import { fetchVoipToken } from '@/api/modules/voip';
import {
  type AcceptedExternalPhoneData,
  type CallEndedData,
  type CallTransferredData,
  type ClientAcceptedCallData,
  type ConferenceStartedData,
  type ConferenceThirdPartyData,
  type InboundCallData,
  type InternalCallData,
  type OutboundMobileCallData,
  type TeamInternalCallData,
  PUSHER_VOIP_EVENTS,
} from '@/components/Voip/constants/events';
import {
  onAcceptedExternalPhone,
  onCallEnded,
  onCallTransferred,
  onClientAcceptedCall,
  onConferenceStarted,
  onConferenceThirdPartyJoined,
  onConferenceThirdPartyLeft,
  onInboundCall,
  onInternalCall,
  onInternalCallCancelled,
  onInternalCallRejected,
  onOutboundMobileCallStarted,
  onTeamInternalCall,
} from '@/components/Voip/VoipActions';
import { flashError } from '@/util/flashNotification';

/**
 * For a clearer idea of how Twilio handles calls, you can read the following documentation:
 * https://www.twilio.com/docs/voice/twiml#twilios-request-to-your-application
 *
 * For reference on the below CallParams, please checkout the Backend Monolith's ConnectController:
 * https://github.com/weerdm/trengo/blob/e0faaa6f8384cc1846a7873143fe5b8c2b745088/app/Http/Api/Voip/Controllers/ConnectController.php
 *
 * The basics of how this works is that via the Device.connect method we use Twilio's custom markup
 * language format (TwiML) to deliver to *our* backend the params it needs in order to properly
 * establish a VOIP call. So the request goes from our frontend, to one of the setup Twilio devices,
 * to Twilio's servers which will then make a callback to our backend with the provided params.
 *
 *
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type CallParams = {
  PhoneNumber: string;
  ChannelPrefix: string;
  ChannelId: string;
  UserId: string;
  type: 'OUTBOUND' | 'INBOUND' | 'INTERN' | 'WIDGET';
  token: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type CallChannel = {
  id: string;
  prefix: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type MobileConnectionParams = {
  token: string;
  phone_number: string;
  channel_id: string;
};

const useVoipStore = defineStore('voip', () => {
  const device = ref<Device | null>(null);
  const call = ref<Call | null>(null);
  const pusherChannel = ref<Channel | null>(null);

  const outputAudioDevices = ref<MediaDeviceInfo[]>([]);
  const inputAudioDevices = ref<MediaDeviceInfo[]>([]);

  const isVoipReady = ref(false);

  async function initializeVoipStore(channelPrefix: string, userId: number) {
    const tokenResponse = await fetchVoipToken();
    const token = tokenResponse.data.token;

    initializePusherEvents(channelPrefix, userId);
    initializeDevice(token);
    getAvailableAudioDevices();

    isVoipReady.value = true;
  }

  async function getAudioPermissions() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      // Stop the stream immediately since we just need the browser permission window to trigger
      stream.getTracks().forEach((track) => track.stop());

      return true;
    } catch (err: unknown) {
      if (err instanceof DOMException && err.name === 'NotAllowedError') {
        flashError(
          "Audio permission were denied. This means you won't be able to make or receive calls. If you'd like to do so, please allow Trengo permission to use your audio devices.",
        );
      } else {
        console.error('Error while getting audio permissions:', err);
      }

      return false;
    }
  }

  async function getAvailableAudioDevices() {
    const hasAudioPermissions = await getAudioPermissions();

    if (!hasAudioPermissions) {
      return;
    }

    if (device?.value?.audio) {
      device.value.audio.availableOutputDevices.forEach((audioDevice) => outputAudioDevices.value.push(audioDevice));
      device.value.audio.availableInputDevices.forEach((audioDevice) => inputAudioDevices.value.push(audioDevice));
    }
  }

  async function initializeDevice(token: string) {
    // https://twilio.com/docs/voice/sdks/javascript/twiliodevice#deviceoptions
    device.value = new Device(token, {
      logLevel: window.APP_ENV === 'production' ? 5 : 1, // Silent on prod, debug otherwise
      closeProtection: true,
      appName: window.APP_ENV === 'production' ? 'prod-frontend' : 'stg-frontend',
      appVersion: __BUILD_INFORMATION__?.hash ?? '',
    });
    device.value.register();
  }

  function initializePusherEvents(channelPrefix: string, userId: number) {
    if (!pusherChannel.value) {
      if (!window.PusherInstance) {
        throw new Error("Pusher Instance wasn't available when setting up VOIP store");
      } else {
        pusherChannel.value = window.PusherInstance.subscribe(`private-voip-${channelPrefix}`);
      }
    }

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.CALL_ACCEPTED_EXTERNAL_PHONE, (data: AcceptedExternalPhoneData) => {
      onAcceptedExternalPhone(data);
    });

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.CALL_ENDED, (data: CallEndedData) => {
      onCallEnded(data);
    });

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.CALL_TRANSFERRED, (data: CallTransferredData) => {
      onCallTransferred(data);
    });

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.CLIENT_ACCEPTED_CALL, (data: ClientAcceptedCallData) => {
      onClientAcceptedCall(data);
    });

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.CONFERENCE_STARTED, (data: ConferenceStartedData) => {
      onConferenceStarted(data);
    });

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.CONFERENCE_THIRD_PARTY_JOINED, (data: ConferenceThirdPartyData) => {
      onConferenceThirdPartyJoined(data);
    });

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.CONFERENCE_THIRD_PARTY_LEFT, (data: ConferenceThirdPartyData) => {
      onConferenceThirdPartyLeft(data);
    });

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.INBOUND_CALL, (data: InboundCallData) => {
      onInboundCall(data);
    });

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.INTERNAL_CALL(userId), (data: InternalCallData) => {
      onInternalCall(data);
    });

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.INTERNAL_CALL_CANCELLED(userId), (data: InternalCallData) => {
      onInternalCallCancelled(data);
    });

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.INTERNAL_CALL_REJECTED(userId), (data: InternalCallData) => {
      onInternalCallRejected(data);
    });

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.OUTBOUND_MOBILE_CALL_STARTED, (data: OutboundMobileCallData) => {
      onOutboundMobileCallStarted(data);
    });

    pusherChannel.value!.bind(PUSHER_VOIP_EVENTS.TEAM_INTERNAL_CALL, (data: TeamInternalCallData) => {
      onTeamInternalCall(data);
    });
  }

  return {
    device,
    call,
    isVoipReady,
    outputAudioDevices,
    inputAudioDevices,

    initializeVoipStore,
    getAudioPermissions,
    getAvailableAudioDevices,
  };
});

export default useVoipStore;
