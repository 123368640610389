<template>
  <div
    v-if="visible"
    class="absolute flex select-none flex-col items-center rounded-lg bg-black p-6 leading-none text-white"
    style="width: 300px; z-index: 99999; right: 20px; top: 20px"
  >
    <div class="flex w-full text-grey-500">
      <i class="text-md material-icons ml-auto cursor-pointer" style="opacity: 50%" @click="closePanel">close</i>
    </div>
    <div class="flex items-center opacity-75">
      <i class="material-icons mr-2 text-sm leading-none">call_received</i>
      Inbound call at
      <strong class="ml-1">{{ call.channel.title }}</strong>
    </div>
    <div class="mt-4">
      <avatar :color="call.contact.color" :abbr="call.contact.abbr" font-size="20px" width="50"></avatar>
    </div>
    <div class="text-md mb-2 mt-4 font-bold leading-none">
      {{ call.contact.full_name }}
    </div>
    <div class="text-sm leading-none">
      {{ call.contact.phone }}
    </div>
    <router-link
      v-if="call.ticket_id"
      :to="'/tickets/' + call.ticket_id"
      class="router-link-exact-active active mt-6 w-full rounded border py-3 text-center hover:text-grey-500"
    >
      Open conversation
    </router-link>
  </div>
</template>

<script>
import eventBus from '@/eventBus';

import Avatar from '../../Avatar';

/**
 * @deprecated This component is marked as being deprecated as a new, more idiomatic solution will
 * be created for this.
 *
 * To track the progress of that work, please refer to https://linear.app/trengo/issue/IO-738.
 */
export default {
  name: 'Sip',
  components: {
    Avatar,
  },

  data() {
    return {
      visible: false,
      endTimer: null,
      call: {},
    };
  },

  mounted() {
    eventBus.$on(
      'pusher.private-agency-' + this.$root.companyProfile.profile.agency_id + '.subscribed',
      this.registerPusherEvents,
    );
  },

  methods: {
    registerPusherEvents() {
      this.$root.agencyChannel.bind('OnSipEvent', this.onSipEvent);
    },

    onSipEvent(da) {
      if (da.targets && da.targets.indexOf(this.$root.user.id) === -1) {
        return;
      }

      if (da['status'] === 'ended' || da['status'] === 'in-progress') {
        if (this.call.id !== da.call.id) {
          return;
        }
      }

      if (da['status'] === 'ringing' && da['direction'] === 'inbound' && !this.call.id) {
        this.call = da.call;
        this.visible = true;
        clearTimeout(this.endTimer);
        this.endTimer = setTimeout(() => {
          this.closePanel();
        }, 50000);
      }

      if (da['status'] === 'in-progress' && da['direction'] === 'inbound') {
        if (da.call.user_id === this.$root.user.id) {
          this.call = da.call;
          this.visible = true;
          clearTimeout(this.endTimer);
        } else {
          this.visible = false;
        }
      }

      if (da['status'] === 'ended') {
        this.closePanel();
      }
    },

    closePanel() {
      this.call = {};
      this.visible = false;
      clearTimeout(this.endTimer);
    },
  },
};
</script>
