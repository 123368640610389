import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3de77784"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "broadcasting-list" }
const _hoisted_2 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_broadcasting_list_header = _resolveComponent("broadcasting-list-header")!
  const _component_broadcasting_list_main = _resolveComponent("broadcasting-list-main")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_broadcasting_list_header, {
        balance: $setup.formattedBalance,
        loading: $setup.balanceIsLoading
      }, null, 8 /* PROPS */, ["balance", "loading"]),
      _createVNode(_component_broadcasting_list_main, {
        list: $props.list,
        loading: $props.loading,
        onInfiniteScroll: $options.onInfinite
      }, null, 8 /* PROPS */, ["list", "loading", "onInfiniteScroll"])
    ])
  ]))
}