<template>
  <div class="broadcasting-list">
    <div class="wrapper">
      <broadcasting-list-header :balance="formattedBalance" :loading="balanceIsLoading" />
      <broadcasting-list-main :list="list" :loading="loading" @infinite-scroll="onInfinite" />
    </div>
  </div>
</template>

<script lang="ts">
import BroadcastingListHeader from '@/components/WabBroadcasting/components/BroadcastingListHeader';
import BroadcastingListMain from '@/components/WabBroadcasting/components/BroadcastingListMain';
import { useBalance } from '@/composables/useBalance';

export default {
  name: 'BroadcastingList',
  components: { BroadcastingListMain, BroadcastingListHeader },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['infinite-scroll'],

  setup() {
    const { formattedBalance, balanceIsLoading } = useBalance();
    return { formattedBalance, balanceIsLoading };
  },

  methods: {
    onInfinite(scroll) {
      this.$emit('infinite-scroll', scroll);
    },
  },
};
</script>

<style scoped lang="scss" src="./BroadcastingList.scss" />
