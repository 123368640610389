/**
 * This file contains all constants (including types) related to Twilio-related VOIP events.
 *
 * The `VoipActions.ts` file contains the actual methods that the Voip store will act on when one of
 * the below events are handled.
 *
 * The file defines two main types of events:
 *
 * 1. PUSHER_VOIP_EVENTS: These events come from Pusher.
 * 2. GENERIC_VOIP_EVENTS: These are internal events that the LegacyVoip singleton was using to take
 * care of generic call state like error states and stuff like that.
 *
 * All the events also have a corresponding Data type that contains the actual payload the current
 * implementation is
 */

import type { Channel, User } from '@/types';
import type Contact from '@/types/contact';
import type Ticket from '@/types/tickets';

export const PUSHER_VOIP_EVENTS = {
  CALL_ACCEPTED_EXTERNAL_PHONE: 'CallAcceptedByExternalPhone',
  CALL_ENDED: 'CallEndedData',
  CALL_TRANSFERRED: 'client-call-transferred',
  CLIENT_ACCEPTED_CALL: 'client-accepted-call',
  CONFERENCE_STARTED: 'ConferenceStartedData',
  CONFERENCE_THIRD_PARTY_JOINED: 'ConferenceThirdPartyJoined',
  CONFERENCE_THIRD_PARTY_LEFT: 'ConferenceThirdPartyLeft',
  INBOUND_CALL: 'InboundCallData',
  INTERNAL_CALL: (userId: number) => `client-${userId}.intern-call`,
  INTERNAL_CALL_CANCELLED: (userId: number) => `client-${userId}.intern-call-cancelled`,
  INTERNAL_CALL_REJECTED: (userId: number) => `client-${userId}.intern-call-rejected`,
  OUTBOUND_MOBILE_CALL_STARTED: 'OutboundMobileCallStarted',
  TEAM_INTERNAL_CALL: 'client-team.intern-call',
} as const;

/**
 * These events are not handled by Pusher and were instead used in the LegacyVoipEvents file as
 * generic event handlers by the Voip singleton. I'm not sure at this stage if these need to be made
 * like this, but I'll keep it in for parity for now.
 *
 * I'm guessing that I'll most likely be getting rid of this and instead manually handling the logic
 * individually where relevant, but to ease the transition phase I'll be doing this for now to make
 * it easier to compare the two solutions side-by-side.
 */
export const GENERIC_VOIP_EVENTS = {
  CALL_STARTED: 'on_call_started',
  ERROR: 'error',
  INBOUND_CALL_INITIALIZED: 'inbound_call_initialized',
  INBOUND_TRANSFER_CALL: 'on_inbound_transfer_call',
  INITIALIZED: 'initialized',
  OUTBOUND_CALL_INITIATED: 'outbound_call_initiated',
  OUTBOUND_CALL_RINGING: 'outbound_call_ringing',
  OUTBOUND_MOBILE_CALL_INITIATED: 'outbound_mobile_call_initiated',
} as const;

/**
 * A base data type that is common between many of the other event payloads. It shouldn't be used
 * itself to avoid tight coupling, instead each payload should have its own separate type that will
 * expand upon this one. That's the reason it's not exported from this file.
 */
type VoipCallBaseData = {
  token: string;
  channel?: Channel;
  contact?: Contact;
  ticket_id?: Ticket['id'];
  user_id?: User['id'];
  status?: 'QUEUED' | 'IN_PROGRESS' | 'ENDED';
};

export type VoipInternalCallBase = VoipCallBaseData & {
  userId: number;
  intern: true;
};

export type AcceptedExternalPhoneData = {
  token: string;
};

export type CallEndedData = VoipCallBaseData & {
  direction?: 'inbound' | 'outbound';
};

export type CallTransferredData = {
  call: VoipCallBaseData;
  user: User;
  user_id: User['id'];
};

export type ClientAcceptedCallData = {
  call: VoipCallBaseData;
  user: User;
};

export type ConferenceStartedData = VoipCallBaseData;

export type ConferenceThirdPartyData = {
  token: string;
  user_id: User['id'];
};

export type InboundCallData = {
  call: VoipCallBaseData;
  targets: number[];
};

export type InternalCallData = {
  call: VoipInternalCallBase;
  from: User;
};

export type OutboundMobileCallData = {
  contact: Contact;
  ticket_id: Ticket['id'];
  user_id: User['id'];
  channel: Channel;
};

export type TeamInternalCallData = InternalCallData & {
  target: number;
};

// Generic event data goes below

export type CallStartedData = VoipCallBaseData;

export type ErrorData = {
  message: string;
};

// Keeping these void ones for now. Presumably there should be no arguments passed here anyways.
export type InboundCallInitializedData = void;

export type InboundTransferCallData = VoipCallBaseData;

export type InitializedData = void;

export type OutboundCallInitiatedData = {
  token: string;
};

export type OutboundCallRingingData = {
  user_id: User['id'];
  ticket_id: Ticket['id'];
  contact: Contact;
  channel: Channel;
};

export type OutboundMobileCallInitiatedData = { token: string };
