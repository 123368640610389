<template>
  <div class="preview flex flex-col items-center justify-center rounded-lg bg-contain bg-center p-12">
    <div
      v-if="shouldShowMessage"
      class="message-width preview__card relative min-w-[94px] break-words rounded-md bg-white px-2 pb-2 pt-3"
    >
      <div>
        <p
          v-if="!!templateHeader && selectedHeaderType === TEMPLATE_HEADER_TYPE.TEXT"
          class="mb-0 text-lg font-bold leading-5"
          v-html="formattedText(templateHeader)"
        />
        <img v-if="selectedHeaderType === TEMPLATE_HEADER_TYPE.IMAGE" :src="imageSrc" class="preview__image" />
        <div class="mt-2 pb-3">
          <p class="mb-0 text-base font-medium leading-5" v-html="formattedText(templateBody)" />
        </div>

        <div class="preview__footer flex items-center justify-between">
          <p
            v-if="!!templateFooter"
            class="footer mb-0 mr-2 w-4/6 break-words text-sm font-normal leading-4"
            v-html="formattedText(templateFooter)"
          />
          <p class="time mb-0 ml-auto text-sm leading-4">{{ currentTime }}</p>
        </div>
      </div>
      <hr v-if="filteredButtons.length" class="w-full preview__full-width-hr -ml-2 border-grey my-2" />
      <ul v-if="filteredButtons.length" class="preview__buttons mb-0 message-width w-full rounded-sm bg-white pl-0">
        <li
          v-for="(btn, index) in filteredButtons"
          :key="btn.id"
          class="last:border-none border-black border-opacity-10 border-b w-full border-grey py-2 list-none mb-0 break-words text-center text-base font-normal leading-5"
        >
          <span v-if="index < 2">
            <external-link2-linear v-if="btn.subType === WA_TEMPLATE_COMPONENT_SUB_TYPE.URL" class="mr-2 inline" />
            <phone-fill v-if="btn.subType === WA_TEMPLATE_COMPONENT_SUB_TYPE.PHONE_NUMBER" class="mr-2 inline" />
            <span>{{ btn.value }}</span>
          </span>
          <span v-if="index === 2">
            <list-unordered2-fill class="mr-2 inline" />
            <span v-if="filteredButtons.length > 2">{{ $t('whatsapp.template_see_all_options') }}</span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { ListUnordered2Fill, ExternalLink2Linear, PhoneFill } from '@trengo/trengo-icons';
import moment from 'moment';
import { defineComponent } from 'vue';

import { TEMPLATE_HEADER_TYPE, WA_TEMPLATE_COMPONENT_SUB_TYPE } from '@/Configs/Constants/whatsapp';
import { formatWhatsAppText } from '@/util/GlobalHelpers';

import type { TemplateButtonItem } from '../types';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'TemplatePreview',
  components: { ExternalLink2Linear, ListUnordered2Fill, PhoneFill },
  props: {
    buttons: {
      type: Array as PropType<TemplateButtonItem[]>,
      default: () => [],
    },
    selectedHeaderType: {
      type: String,
      default: 'text',
    },
    templateBody: {
      type: String,
      default: '',
    },
    templateHeader: {
      type: String,
      default: '',
    },
    templateFooter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentTime: moment().format('HH:mm'),
      timeIntervalId: null,
      TEMPLATE_HEADER_TYPE,
      WA_TEMPLATE_COMPONENT_SUB_TYPE,
    };
  },
  computed: {
    imageSrc() {
      return this.templateHeader ? this.templateHeader : `${this.$root.assetsURL}img/wabusiness/template-image.svg`;
    },
    shouldShowMessage(): boolean {
      return Boolean(
        this.templateBody ||
          this.templateHeader ||
          this.templateFooter ||
          this.selectedHeaderType === TEMPLATE_HEADER_TYPE.IMAGE,
      );
    },
    filteredButtons(): TemplateButtonItem[] {
      return (this.buttons as TemplateButtonItem[]).slice(0, 3).filter((btn: TemplateButtonItem) => btn.value);
    },
  },
  mounted() {
    this.setCurrentTime();
  },
  beforeUnmount() {
    clearInterval(this.timeIntervalId);
  },
  methods: {
    formattedText(text: string): string {
      return formatWhatsAppText(text);
    },
    setCurrentTime(): void {
      this.timeIntervalId = setInterval(() => {
        this.currentTime = moment().format('HH:mm');
      }, 1000);
    },
  },
});
</script>

<style lang="scss" scoped src="./TemplatePreview.scss" />
