import {
  type AcceptedExternalPhoneData,
  type CallEndedData,
  type CallTransferredData,
  type ClientAcceptedCallData,
  type ConferenceStartedData,
  type ConferenceThirdPartyData,
  type InboundCallData,
  type InternalCallData,
  type OutboundMobileCallData,
  type TeamInternalCallData,
} from '@/components/Voip/constants/events';

export function onAcceptedExternalPhone(data: AcceptedExternalPhoneData) {
  console.log(data);
}

export function onCallEnded(data: CallEndedData) {
  console.log(data);
}

export function onCallTransferred(data: CallTransferredData) {
  console.log(data);
}

export function onClientAcceptedCall(data: ClientAcceptedCallData) {
  console.log(data);
}

export function onConferenceStarted(data: ConferenceStartedData) {
  console.log(data);
}

export function onConferenceThirdPartyJoined(data: ConferenceThirdPartyData) {
  console.log(data);
}

export function onConferenceThirdPartyLeft(data: ConferenceThirdPartyData) {
  console.log(data);
}

export function onInboundCall(data: InboundCallData) {
  console.log(data);
}

export function onInternalCall(data: InternalCallData) {
  console.log(data);
}

export function onInternalCallCancelled(data: InternalCallData) {
  console.log(data);
}

export function onInternalCallRejected(data: InternalCallData) {
  console.log(data);
}

export function onOutboundMobileCallStarted(data: OutboundMobileCallData) {
  console.log(data);
}

export function onTeamInternalCall(data: TeamInternalCallData) {
  console.log(data);
}
