import { ENDPOINT } from '@/api/constants';
import { request } from '@/util';

import type { AxiosPromise } from 'axios';

type TokenResponse = {
  token: string;
};

export function fetchVoipToken(channelId?: number): AxiosPromise<TokenResponse> {
  return request(ENDPOINT.VOIP_TOKEN, 'GET', {}, {}, { channel_id: channelId });
}
