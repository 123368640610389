import { request } from '@/util/request';

import { ENDPOINT } from '../constants';

import type { AxiosPromise } from 'axios';

type TopUpRequest = {
  amount: number;
};

type TopUpResponse = {
  balance: number;
};

type SeatBasedBalanceResponse = {
  balance: string;
  balance_unformatted: number;
};

type UsageBasedBalanceResponse = {
  balance: {
    commitment: number;
    consumption: number;
    balance: number;
  };
};

export function topUpBalanceForInvoice(topupRequest: TopUpRequest): AxiosPromise<TopUpResponse> {
  return request(ENDPOINT.CHARGEBEE_TOP_UP_INVOICE, 'POST', topupRequest);
}

export const fetchBalanceForUsageBased = () => request<UsageBasedBalanceResponse>(ENDPOINT.BALANCE_USAGE_BASED, 'GET');

export const fetchBalanceForSeatBased = () => request<SeatBasedBalanceResponse>(ENDPOINT.BALANCE_SEAT_BASED, 'GET');
