import { mapValues } from 'lodash';

import { authenticateChannel } from '@/api/modules/email';
import { CHANNEL_TYPE } from '@/Configs/Constants';
import { NYLAS_API_V3 } from '@/Configs/Constants/ChannelLandingPageTypes';

/**
 *
 * @returns {{FACEBOOK: string, INSTAGRAM: string, CHAT: string,  VOIP: string, WHATSAPP: string, EMAIL: string, TELEGRAM: string, HELP_CENTER: string, GBM: string, SMS: string, WA_BUSINESS: string}}
 * @constructor
 */
export function ChannelsIconsMapper() {
  const mapped_channels = mapValues(CHANNEL_TYPE, function (value) {
    return `${Assets_path}img/channels_icons/${value.toLowerCase()}.svg`;
  });
  return mapped_channels;
}

export const authenticateNylasChannel = async () => {
  try {
    const res = await authenticateChannel(NYLAS_API_V3);
    window.location.href = res.data.url;
  } catch (err) {
    console.error(err);
  }
};

export default { ChannelsIconsMapper, authenticateNylasChannel };
