<template>
  <div class="flex items-center justify-between">
    <h1 class="t-text-desktop-h5 m-0">{{ $t('broadcast.broadcast_overview_title') }}</h1>

    <div class="flex items-center">
      <router-link
        v-if="userStore.hasPermission(PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE)"
        :to="topUpBalanceUrl"
        data-test="balance-btn"
      >
        <atomic-button btn-style="secondary" class="mr-4 flex items-center">
          {{ $t('broadcast.balance') }} {{ loading ? '...' : balance }}
          <external-link2-linear class="ml-2" />
        </atomic-button>
      </router-link>

      <router-link to="/broadcasting/new" data-test="new-broadcast-btn">
        <atomic-button class="new-btn">{{ $t('broadcast.new_broadcast') }}</atomic-button>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { ExternalLink2Linear } from '@trengo/trengo-icons';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

import { PERMISSION } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import { useUserStore, useCompanyProfileStore } from '@/store/pinia';

export default defineComponent({
  name: 'BroadcastingListHeader',
  components: {
    ExternalLink2Linear,
  },
  props: {
    balance: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      PERMISSION,
      SETTINGS_URL,
    };
  },
  computed: {
    ...mapStores(useUserStore, useCompanyProfileStore),
    topUpBalanceUrl() {
      return this.companyProfileStore.isUsageBasedPricingModel
        ? SETTINGS_URL.ADMIN__USAGE_OVERVIEW
        : SETTINGS_URL.ADMIN__BALANCE_SETTINGS;
    },
  },
});
</script>
