import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-d2278e44"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "preview flex flex-col items-center justify-center rounded-lg bg-contain bg-center p-12" }
const _hoisted_2 = {
  key: 0,
  class: "message-width preview__card relative min-w-[94px] break-words rounded-md bg-white px-2 pb-2 pt-3"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "mt-2 pb-3" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "preview__footer flex items-center justify-between" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "time mb-0 ml-auto text-sm leading-4" }
const _hoisted_10 = {
  key: 0,
  class: "w-full preview__full-width-hr -ml-2 border-grey my-2"
}
const _hoisted_11 = {
  key: 1,
  class: "preview__buttons mb-0 message-width w-full rounded-sm bg-white pl-0"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_external_link2_linear = _resolveComponent("external-link2-linear")!
  const _component_phone_fill = _resolveComponent("phone-fill")!
  const _component_list_unordered2_fill = _resolveComponent("list-unordered2-fill")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.shouldShowMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", null, [
            (!!_ctx.templateHeader && _ctx.selectedHeaderType === _ctx.TEMPLATE_HEADER_TYPE.TEXT)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  class: "mb-0 text-lg font-bold leading-5",
                  innerHTML: _ctx.formattedText(_ctx.templateHeader)
                }, null, 8 /* PROPS */, _hoisted_3))
              : _createCommentVNode("v-if", true),
            (_ctx.selectedHeaderType === _ctx.TEMPLATE_HEADER_TYPE.IMAGE)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: _ctx.imageSrc,
                  class: "preview__image"
                }, null, 8 /* PROPS */, _hoisted_4))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("p", {
                class: "mb-0 text-base font-medium leading-5",
                innerHTML: _ctx.formattedText(_ctx.templateBody)
              }, null, 8 /* PROPS */, _hoisted_6)
            ]),
            _createElementVNode("div", _hoisted_7, [
              (!!_ctx.templateFooter)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    class: "footer mb-0 mr-2 w-4/6 break-words text-sm font-normal leading-4",
                    innerHTML: _ctx.formattedText(_ctx.templateFooter)
                  }, null, 8 /* PROPS */, _hoisted_8))
                : _createCommentVNode("v-if", true),
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.currentTime), 1 /* TEXT */)
            ])
          ]),
          (_ctx.filteredButtons.length)
            ? (_openBlock(), _createElementBlock("hr", _hoisted_10))
            : _createCommentVNode("v-if", true),
          (_ctx.filteredButtons.length)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredButtons, (btn, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: btn.id,
                    class: "last:border-none border-black border-opacity-10 border-b w-full border-grey py-2 list-none mb-0 break-words text-center text-base font-normal leading-5"
                  }, [
                    (index < 2)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                          (btn.subType === _ctx.WA_TEMPLATE_COMPONENT_SUB_TYPE.URL)
                            ? (_openBlock(), _createBlock(_component_external_link2_linear, {
                                key: 0,
                                class: "mr-2 inline"
                              }))
                            : _createCommentVNode("v-if", true),
                          (btn.subType === _ctx.WA_TEMPLATE_COMPONENT_SUB_TYPE.PHONE_NUMBER)
                            ? (_openBlock(), _createBlock(_component_phone_fill, {
                                key: 1,
                                class: "mr-2 inline"
                              }))
                            : _createCommentVNode("v-if", true),
                          _createElementVNode("span", null, _toDisplayString(btn.value), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (index === 2)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                          _createVNode(_component_list_unordered2_fill, { class: "mr-2 inline" }),
                          (_ctx.filteredButtons.length > 2)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.$t('whatsapp.template_see_all_options')), 1 /* TEXT */))
                            : _createCommentVNode("v-if", true)
                        ]))
                      : _createCommentVNode("v-if", true)
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ]))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}