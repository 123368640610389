import { request } from '@/util/request';

import type { AxiosPromise } from 'axios';

export type AuthenticateChannelResponse = {
  url: string;
};

type LoadChannelsResponse = {
  access_token: string;
  email_address: string;
  sub_account_id: string;
};

export function authenticateChannel(channelName: string): AxiosPromise<AuthenticateChannelResponse> {
  return request(`/api/v2/${channelName}/authenticate`, 'POST');
}

export function loadChannelSettings(
  channelName: string,
  params?: Record<string, any>,
  channelId?: number,
): AxiosPromise<LoadChannelsResponse> {
  const url = channelId ? `/api/v2/${channelName}/pages/${channelId}` : `/api/v2/${channelName}/pages`;
  return request(url, 'GET', {}, {}, params);
}

export async function importMessages(channelId: number) {
  return request(`/api/v2/channels/${channelId}/importMessages`, 'POST');
}
