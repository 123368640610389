<template>
  <div class="row-body">
    <admin-section-title :title="getName(channel)"></admin-section-title>
    <div class="row-col">
      <div class="row-col">
        <div v-if="showChannelList" id="settings-primary" class="col-xs modal fade aside aside-md b-r w-medium w-200px">
          <setting-sidebar :channels="channels" :channel-name="getName(channel)" @create="createNewChannel(channel)" />
        </div>

        <div class="row-col">
          <router-view
            v-if="loaded"
            :key="$route.params.id"
            :channels="channels"
            @reload-channel-index-list="loadList"
          />
        </div>
      </div>
    </div>
    <create-email-modal-new-view v-if="isNewEmailChannelView" v-model="modalOpen" />
    <create-email-modal v-else @update-loaded="handleLoadedUpdate"></create-email-modal>
    <create-voip-modal></create-voip-modal>
    <create-sms-modal></create-sms-modal>
    <create-wa-bsp-modal v-model="showWaBspModal"></create-wa-bsp-modal>
  </div>
</template>

<script type="text/babel" lang="ts">
import CreateEmailModalNewView from '@/components/Channels/CreateEmailModalNewView.vue';
import { CHANNEL_TYPE, FEATURE_FLAG_CHANNELS, FEATURE_FLAG_VOLUME } from '@/Configs/Constants';
import { NYLAS_CHANNEL_RECONNECTION_MESSAGES } from '@/Configs/Constants/emailChannels';
import eventBus from '@/eventBus';
import { useFeatureFlagStore } from '@/store/pinia';
import { authenticateNylasChannel } from '@/util/ChannelsUtils';

import SettingSidebar from './components/SettingSidebar';
import CreateWaBspModal from './CreateChannelModals/WhatsApp/CreateWaBspModal';
import createEmailModal from './CreateEmailModal.vue';
import createSmsModal from './CreateSmsModal.vue';
import createVoipModal from './CreateVoipModal.vue';

type ChannelNames = {
  [key: string]: string;
};

const channelNames: ChannelNames = {
  sms: 'SMS',
  chat: 'Live chat',
  voip: 'Voice',
  telegram: 'Telegram',
  instagram: 'Instagram',
  email: 'Email',
  facebook: 'Facebook',
  zipwhip: 'Zipwhip',
  wa_business: 'WA Business',
  custom: 'Custom',
  gbm: 'Gbm',
};

export default {
  name: 'Channels',

  components: {
    CreateEmailModalNewView,
    CreateWaBspModal,
    SettingSidebar,
    createEmailModal,
    createVoipModal,
    createSmsModal,
  },
  async beforeRouteEnter(to, _from, next) {
    const channelParam = to.params.channel;
    const isGbmChannel = channelParam ? channelParam.toLowerCase() === CHANNEL_TYPE.GBM.toLowerCase() : false;

    if (isGbmChannel) {
      // Need this because we need to check for feature flag before the creation of the component
      // eslint-disable-next-line vue-composable/composable-placement
      const isHideGbmEnabled = useFeatureFlagStore().isEnabled(FEATURE_FLAG_CHANNELS.TC_HIDE_GBM);

      if (isHideGbmEnabled) {
        next('/tickets');
        return;
      }
    }

    next();
  },
  setup() {
    const featureFlagStore = useFeatureFlagStore();
    return { featureFlagStore };
  },
  data() {
    return {
      loaded: false,
      channels: [],
      channel: this.$route.params.channel as string,
      showWaBspModal: false,
      modalOpen: false,
    };
  },
  computed: {
    showChannelList() {
      // hide channel list on create or edit
      return (
        (!['/create', '/create360dialog'].some((url) => this.$route.path.endsWith(url)) && !this.$route.params.id) ||
        this.isDesktop
      );
    },
    isDesktop() {
      return window.matchMedia('(min-width:1900px)').matches;
    },
    isNewEmailChannelView() {
      return this.featureFlagStore.isEnabled(FEATURE_FLAG_VOLUME.TVE_NYLAS_DEPRECATE_CHANNEL_PROVIDERS);
    },
  },
  watch: {
    '$route.params'() {
      this.channel = this.$route.params.channel;
      this.loadList(false);
    },
  },
  mounted() {
    this.checkAuthErrorMessage();
    this.loadList(false);

    const { status: nylasReconnectionStatus, message: nylasReconnectionMessage } = this.$route.query;
    if (nylasReconnectionStatus && nylasReconnectionMessage) {
      this.handleNylasChannelReconnection(nylasReconnectionMessage);
    }

    if (this.$route.query.create) {
      this.createNewChannel('email');
    }

    eventBus.$on('channels.reload', () => this.loadList(true));

    eventBus.$on('showWaBspModal', () => {
      this.showWaBusinessModal();
    });
  },

  unmounted() {
    eventBus.$off('channels.reload');
    eventBus.$off('showWaBspModal');
  },

  methods: {
    loadList(reload_root = true) {
      axios.get('/api/v2/channels', { params: { type: this.channel } }).then((result) => {
        this.channels = result.data.data;
        this.loaded = true;
        if (reload_root) {
          this.$root.loadAuthorizedChannels();
        }
      });
    },
    getName(type: keyof ChannelNames) {
      if (channelNames[type as keyof ChannelNames]) {
        return channelNames[type as keyof ChannelNames];
      }
      return '';
    },
    showEmailModal() {
      this.modalOpen = this.isNewEmailChannelView;
      if (!this.isNewEmailChannelView) {
        $('#CreateEmailModal').modal('show');
      }
    },
    showVoipModal() {
      $('#CreateVoipModal').modal();
    },
    showWaBusinessModal() {
      this.showWaBspModal = true;
    },
    showSmsModal() {
      $('#CreateSmsModal').modal();
    },
    createNewChannel(channel: string) {
      if (channel && channel === 'email') {
        const hideCreateForwardingChannel = this.featureFlagStore.isEnabled(
          FEATURE_FLAG_VOLUME.TVE_STRATEGY_HIDE_CREATE_FORWARDING_CHANNEL,
        );

        if (hideCreateForwardingChannel) {
          authenticateNylasChannel();
        } else {
          this.showEmailModal();
        }
      } else if (channel && channel === 'voip') {
        this.showVoipModal();
      } else if (channel && channel === 'wa_business') {
        this.showWaBusinessModal();
      } else if (channel && channel === 'sms') {
        this.showSmsModal();
      } else {
        this.$router.push('/admin/channels2/' + channel + '/create');
      }
    },
    checkAuthErrorMessage() {
      if (this.$route.query.error === 'access_denied') {
        this.flashError('You need to authorize Trengo to access your account');
      }
    },
    handleLoadedUpdate(val: boolean) {
      this.loaded = val;
    },
    handleNylasChannelReconnection(nylasReconnectionMessage: string) {
      if (nylasReconnectionMessage === NYLAS_CHANNEL_RECONNECTION_MESSAGES.UPDATE_FAILED_DIFFERENT_CHANNEL) {
        this.flashError(this.$t('channels.update_failed_different_channel'));
      }
    },
  },
};
</script>
