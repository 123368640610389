export const formatCurrency = (cents: number | undefined) => {
  if (!cents) return '';

  return new Intl.NumberFormat('nl-NL', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(cents / 100);
};

export const formatPrice = (cents: number, minimumFractionDigits?: number) => {
  const minFractionDigits = minimumFractionDigits ? minimumFractionDigits : cents % 100 === 0 ? 0 : 2;
  const formattedPrice = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    signDisplay: 'never',
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: 2,
  }).format(cents / 100);

  return cents < 0 ? `-${formattedPrice}` : formattedPrice;
};

const EURO_IN_CENTS = 100;
export const toCents = (amount: number) => amount * EURO_IN_CENTS;
